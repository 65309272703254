import React from 'react';
import _ from 'lodash';
import {graphql} from 'gatsby';

import {Layout} from '../components/index';
import {toStyleObj, withPrefix, htmlToReact} from '../utils';
// import 'bootstrap/dist/css/bootstrap.min.css';
// let { Tab, Tabs } = react-bootstrap;
// this minimal GraphQL query ensures that when 'gatsby develop' is running,
// any changes to content files are reflected in browser
export const query = graphql`
  query($url: String) {
    sitePage(path: {eq: $url}) {
      id
    }
  }
`;

function changeTab(){

}

export default class InformationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "enterprice_info"
    };    
    this.changeText = this.changeText.bind(this);
  }

  changeText(event) {
    this.setState({
    name: event.target.getAttribute('id')
    }); 
  }
    render() { 
      let page = this.props.pageContext.frontmatter;
      let section1 = this.props.pageContext.frontmatter.section1;
      let section2 = this.props.pageContext.frontmatter.section2;
      let section3 = this.props.pageContext.frontmatter.section3;
        console.log(section1);
        return (
            <Layout {...this.props}>
              <article className="post post-full page_sect mission_support">
                <header className="page-header has-gradient outer">
                  {_.get(this.props, 'pageContext.frontmatter.image', null) && (
                  <div className="bg-img" style={toStyleObj('background-image: url(\'' + withPrefix(_.get(this.props, 'pageContext.frontmatter.image', null)) + '\')')}/>
                  )}
                  <div className="inner-sm">
                    <h1 className="post-title">{_.get(this.props, 'pageContext.frontmatter.title', null)}</h1>
                    {_.get(this.props, 'pageContext.frontmatter.subtitle', null) && (
                    <div className="post-subtitle">
                      {htmlToReact(_.get(this.props, 'pageContext.frontmatter.subtitle', null))}
                    </div>
                    )}
                  </div>
                </header>

                <div className="tab_menu">
                      {_.get(page, 'menus', null) && (
                        <div className="header_buttons">
                        {_.map(_.get(page, 'menus', null), (item, item_idx) => (
                             <div className="tab_btn">
                              {_.get(item, 'label', null) && (
                                <button id={_.get(item, 'id', null)} onClick={this.changeText} className={ item.id == this.state.name ? 'active' : 'hide'} >
                                  {(_.get(item, 'label', null))}
                                </button>
                              )}
                             </div>
                        ))}
                        </div>
                      )}
                    </div>

                    <a className="go_back" href="../#capability_grid">
                        Back
                    </a>
                <div className="inner outer">
                  <div className="page-content">
                    <span className="inner_sect_ball1"></span>
                    <span className="inner_sect_ball2"></span>
                    <span className="inner_sect_ball3"></span>
                    {htmlToReact(_.get(this.props, 'pageContext.html', null))}
                    
                    {/* enterprice information */}
                    <div className="section tab_cb" data-target="enterprice_info" data-active={this.state.name == 'enterprice_info'  ? 'active' : ''}> 
                      <div className="sub_header text-center">
                        {_.get(page, 'section.title', null) && (
                        <h3 className="section_title">
                          {(_.get(page, 'section.title', null))}
                        </h3>
                        )}

                        {_.get(page, 'section.description', null) && (
                          <div className="punch_dialogue">
                            <p className="description fw-600 color_blue">
                              {(_.get(page, 'section.description', null))}
                            </p>
                          </div>
                        )}
                        {_.get(page, 'section.description2', null) && (
                          <p className="description ">
                            {(_.get(page, 'section.description2', null))}
                          </p>
                        )} 
                      </div>
                        
                      {_.get(page, 'section.grid', null) && (
                        <div className="grid ">
                           {_.map(_.get(page, 'section.grid', null), (item, item_idx) => (
                             console.log(item),
                            <div key={item_idx} className="grid-item card-cust">
                              <div className="box">

                                {_.get(item, 'title', null) && (
                                <h3 className="grid-item-title line-left">
                                    {_.get(item, 'title', null)}
                                </h3>
                                )}
                                {_.get(item, 'img', null) && (
                                <div className="grid-item-image card_image">
                                  <img src={withPrefix(_.get(item, 'img', null))} alt={_.get(item, 'image_alt', null)} />
                                </div>
                                )}
                                <div className="grid_cust">
                         
                                    {_.get(item, 'description', null) && (
                                    <div className="box-content">
                                      {htmlToReact(_.get(item, 'description', null))}
                                    </div>
                                    )}
                                </div>
                              </div>
                            </div>
                            ))}
                        </div>
                      )}
                    </div>

                    <div className="section tab_cb" data-target="operation_maintenance"  data-active={this.state.name == 'operation_maintenance'  ? 'active' : ''}>
                      <div className="sub_header text-center">
                          <h3 className="section_title">
                          Operations and Maintenance Services 
                          </h3>

                            <div className="punch_dialogue">
                              <p className="description fw-600 color_blue">
                                Day-to-day vigilance and responsiveness.  Disciplined prevention using  leading best practices and CMMI Level 5 processes.  Reliable, secure operations to support critical agency mission systems
                              </p>
                            </div>
                            <p className="description ">
                            We understand large federal enterprises from business, technology, stakeholder, and end-user perspectives. We understand physical servers – and we understand virtualization. From Cisco switches to the Cloud, Business Integra provides an array of IT services that keep global systems afloat.  We have a keen understanding of the interplay between data centers, infrastructure, O&M, applications, and help desks. This broad, in-depth, complex view of the IT that goes into daily operations is why federal agencies task us again and again to support their networks and operations. We deploy only top talent – certified technicians and recognized subject-matter experts – into the field. Here are but a few samples of our O&M activities:
                            </p>
                      </div>

                      {_.get(page, 'section.grid-operation', null) && (
                        <div className="grid ">
                           {_.map(_.get(page, 'section.grid-operation', null), (item, item_idx) => (
                             console.log(item),
                            <div key={item_idx} className="grid-item card-cust">
                              <div className="box">

                                    {_.get(item, 'title', null) && (
                                    <h3 className="grid-item-title line-left">
                                        {_.get(item, 'title', null)}
                                    </h3>
                                    )}

                                  <div className="grid_cust">
                                    {_.get(item, 'img', null) && (
                                    <div className="grid-item-image ">
                                      <img src={withPrefix(_.get(item, 'img', null))} alt={_.get(item, 'image_alt', null)} />
                                    </div>
                                    )}
                                    <div className="grid_cust">
                                        {_.get(item, 'description', null) && (
                                        <div className="box-content">
                                          {htmlToReact(_.get(item, 'description', null))}
                                        </div>
                                        )}
                                    </div>
                                  </div>
                              </div>
                            </div>
                            ))}
                        </div>
                      )}
                    </div>

                    <div className="section tab_cb" data-target="cloud_adoption" data-active={this.state.name == 'cloud_adoption'  ? 'active' : ''}>
                      <div className="sub_header text-center"> 
                          {_.get(section1, 'title', null) && (
                          <h3 className="section_title">
                            {(_.get(section1, 'title', null))}
                          </h3>
                          )}

                          <div className="punch_dialogue"> 
                            {_.get(section1, 'title', null) && (
                            <p className="description fw-600 color_blue">
                              {(_.get(section1, 'description', null))}
                            </p>
                            )}
                          </div>
                      </div>

                      {_.get(section1, 'grid-items', null) && (
                        <div className="grid ">
                          {_.map(_.get(section1, 'grid-items', null), (item, item_idx) => (
                            <div key={item_idx} className="grid-item card-cust">
                              <div className="box">
                                  {_.get(item, 'title', null) && (
                                  <h3 className="grid-item-title line-left">
                                      {_.get(item, 'title', null)}
                                  </h3>
                                  )}

                                  <div className="grid_cust">
                                    <div className="grid_cust">
                                        {_.get(item, 'description', null) && (
                                        <div className="box-content">
                                          {htmlToReact(_.get(item, 'description', null))}
                                        </div>
                                        )}
                                    </div>
                                  </div>
                              </div>
                            </div>
                            ))}
                        </div>
                      )}
                    </div>


                    <div className="section tab_cb" data-target="devsecops" data-active={this.state.name == 'devsecops'  ? 'active' : ''}>
                      <div className="sub_header text-center"> 
                          {_.get(section2, 'title', null) && (
                          <h3 className="section_title">
                            {(_.get(section2, 'title', null))}
                          </h3>
                          )}

                          <div className="punch_dialogue"> 
                            {_.get(section2, 'title', null) && (
                            <p className="description fw-600 color_blue">
                              {(_.get(section2, 'description', null))}
                            </p>
                            )}
                          </div>
                      </div>

                      {_.get(section2, 'grid-items', null) && (
                        <div className="grid ">
                          {_.map(_.get(section2, 'grid-items', null), (item, item_idx) => (
                            <div key={item_idx} className="grid-item card-cust">
                              <div className="box">
                                  {_.get(item, 'title', null) && (
                                  <h3 className="grid-item-title line-left">
                                      {_.get(item, 'title', null)}
                                  </h3>
                                  )}

                                  <div className="">
                                    <div className="grid_cust">
                                        {_.get(item, 'description', null) && (
                                        <div className="box-content">
                                          {htmlToReact(_.get(item, 'description', null))}
                                        </div>
                                        )}
                                    </div>

                                    {_.get(item, 'list', null) && (
                                      <div className="list_wrapper">
                                        <ul >
                                          {_.map(_.get(item, 'list', null), (item, item_idx) => (
                                            <li>{_.get(item, 'item', null)}</li>
                                          ))}
                                        </ul>
                                      </div>
                                    )}

                                  </div>
                              </div>
                            </div>
                            ))}
                        </div>
                      )}
                    </div>

                    <div className="section tab_cb" data-target="network_infrastructure" data-active={this.state.name == 'network_infrastructure'  ? 'active' : ''}>
                      <div className="sub_header text-center"> 
                          {_.get(section3, 'title', null) && (
                          <h3 className="section_title">
                            {(_.get(section3, 'title', null))}
                          </h3>
                          )}

                          <div className="punch_dialogue"> 
                            {_.get(section3, 'title', null) && (
                            <p className="description fw-600 color_blue">
                              {(_.get(section3, 'description', null))}
                            </p>
                            )}
                          </div>
                      </div>

                      {_.get(section3, 'grid-items', null) && (
                        <div className="grid ">
                          {_.map(_.get(section3, 'grid-items', null), (item, item_idx) => (
                            <div key={item_idx} className="grid-item card-cust">
                              <div className="box">
                                  {_.get(item, 'title', null) && (
                                  <h3 className="grid-item-title line-left">
                                      {_.get(item, 'title', null)}
                                  </h3>
                                  )}

                                  <div className="">
                                    <div className="grid_cust">
                                        {_.get(item, 'description', null) && (
                                        <div className="box-content">
                                          {htmlToReact(_.get(item, 'description', null))}
                                        </div>
                                        )}
                                    </div>
                                  </div>
                              </div>
                            </div>
                            ))}
                        </div>
                      )}
                    </div>

                  </div>
                </div>
              </article>

            </Layout>
        );
    }
}
